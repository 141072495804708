//通用的头组件
<template>
  <div class="common_head">
    <!--  导航条部分 -->
    <div class="content">
      <!-- 占位 -->
      <div class="head_left">
        <!-- 导航栏部分 -->
        <div
          class="item_left_title"
          v-for="(item, index) in topTitleArr"
          :key="index"
        >
          <span
            :class="['title_name', { child_nav: index != 0 }]"
            @click.stop="doClickAction(item, index)"
            >{{ item.name }}</span
          >
          <!-- 最后一个位置不显示右箭头 -->
          <i
            class="el-icon-arrow-right title_icon"
            v-if="index != topTitleArr.length - 1"
          ></i>
        </div>
      </div>

      <!-- 消息模块 -->
      <div class="message" @click.stop="goSysMessage">
        <!-- 有消息时展示 -->
        <el-badge :value="unReadMsgNum" :max="99" v-if="unReadMsgNum != 0">
          <div class="msg_view">
            <i class="iconfont icon-xiaoxi"></i>
            <span class="num_tip margin_left_4">消息</span>
          </div>
        </el-badge>

        <!-- 没有消息展示 -->
        <div class="msg_view" v-else>
          <i class="iconfont icon-xiaoxi"></i>
          <span class="num_tip margin_left_4">消息</span>
        </div>

        <!-- <i class="iconfont icon-xiaoxi"></i>
                <div class="tip">
                    <span class="num_tip margin_left_4">提醒</span>
                    <span class="num_txt" v-if="unReadMsgNum != 0">{{unReadMsgNum}}</span>
                </div> -->
      </div>

      <!-- 登录和登录后显示的头像以及角色类型部分 -->
      <div class="user_message margin_right_20">
        <div style="flex: 1"></div>

        <div
          class="login_info"
          @mousemove="isShowPop = true"
          @mouseleave="isShowPop = false"
        >
          <img class="user_img" :src="user_img" />

          <div class="user_type">{{ userName }}</div>

          <!-- 用户资料,我的班级,退出登陆的Pop animated fadeIn :class="{move:isShowPop}" -->
          <transition name="anim">
            <div class="person_msg" v-if="isShowPop">
              <div
                class="item_pop"
                v-for="(item, index) in navArr"
                :key="index"
                @click.stop="goItemPage(index)"
              >
                {{ item.name }}
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      //用户名称
      userName: "",

      //用户头像
      user_img: "",

      //是否显示Pop标识
      isShowPop: false,

      //Pop的数据
      navArr: [
        { name: "用户资料" },
        { name: "修改密码" },
        { name: "退出登录" },
      ],

      //未读消息
      unReadMsgNum: 0,

      //获取未读消息的定时器
      timer: null,
    };
  },
  created() {
    //初始化弹出框的数据
    // this.initPopData();
  },
  mounted() {
    //设置用户信息
    this.setUserInfo();

    //请求一次获取未读消息
    this.httpUnReadMsg();

    //开启定时器获取未读消息
    this.timer = setInterval(() => {
      this.httpUnReadMsg();
    }, 60000);

    //注册修改个人资料的更新
    this.$bus.on("updateUserInfo", (updateUserName) => {
      this.userName = updateUserName;
    });

    //注册进入消息页面时清空消息
    this.$bus.on("updateUnreadMsg", (updateUserName) => {
      this.unReadMsgNum = 0;
    });
  },
  methods: {
    //根据用户角色来配置弹出框的数据
    initPopData() {
      this.navArr.splice(0, this.navArr.length);
    },

    //获取用户信息
    setUserInfo() {
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      if (!this.isEmpty(userInfo)) {
        this.user_img = userInfo.userImg;
        this.userName = userInfo.name;
      }
    },

    //显示Pop弹框的方法
    showPop() {
      this.$emit("showPop");
    },

    //关闭Pop弹框的方法
    closePop() {
      this.$emit("closePop");
    },

    //跳转不同页面
    goItemPage(index) {
      //获取当前的导航条的名称
      let navArrName = this.navArr[index].name;
      if (navArrName == "用户资料") {
        //用户资料
        //   if (this.$route.path != "/Home/UserInfo") {
        //       this.$router.push({ path: "/Home/UserInfo" });
        // }
        //弹出用户资料的弹框
        this.$emit("showUserInfo");
      } else if (navArrName == "修改密码") {
        // if (this.$route.path != "/Home/UpdatePwd") {
        //     //获取登录用户的昵称,电话,以及id
        //     let userInfo = JSON.parse(localStorage.getItem("userInfo"));
        //     let id = userInfo.id;
        //     let name = userInfo.name;
        //     let tel = userInfo.tel;
        //     this.$router.push({
        //         path: "/Home/UpdatePwd",
        //         query: { name, tel, id },
        //     });
        // }
        //弹出用户资料的弹框
        this.$emit("showUpdatePwd");
      } else if (navArrName == "退出登录") {
        //退出登录
        // this.$emit("showLogout");
        //跳转到登录界面
        this.$router.replace("/");
      }
      this.isShowPop = false;
    },

    //跳转到系统消息界面
    goSysMessage() {
      if (this.$route.path != "/Home/SysMessage") {
        //将未读消息数置为''
        this.unReadMsgNum = 0;
        this.$router.push({ path: "/Home/SysMessage" });
      }
    },

    //获取系统未读消息
    async httpUnReadMsg() {
      //请求服务器做删除的操作
      //获取页面消息
      let param = this.getHttpParams();
      param.type = 3;
      //给个固定schooId
      param.schoolId = 1;
      let res = await this.$http.fetchPost(this.$api.MESSAGEREMINDER, param);
      if (res.data.state == 200) {
        //获取未读消息
        this.unReadMsgNum = res.data.data;

        // console.log("请求到的消息数是:", this.unReadMsgNum);
      } else {
        //直接将服务器返回的错误内容弹出提示
        this.showErrorMsg(this.getMesage(res.data.state));
      }
    },

    //顶部标题栏的的菜单点击事件
    doClickAction(item, index) {
      this.$emit("clickTopTitleNav", { item, index });
    },
  },
  components: {},
  beforeDestroy() {
    //销毁时关闭事件
    this.$bus.off("updateUserInfo");

    this.$bus.off("refrshUnReadMsg");

    this.$bus.off("updateUserInfo");

    this.$bus.off("updateUnreadMsg");

    //清除定时器
    clearInterval(this.timer);
  },

  props: ["topTitleArr"],
};
</script>
<style lang="scss" scoped>
.common_head {
  height: 76px;
  width: 100%;
  background: white;
  .content {
    height: 100%;
    position: relative;
    margin: 0 auto;
    display: flex;

    .head_left {
      flex: 1;
      display: flex;
      align-items: center;
      margin-left: 14px;

      .title_name {
        color: #303133;
        font-weight: 700;
        cursor: pointer;
      }

      .title_icon {
        margin: 0 4px;
      }

      .child_nav {
        color: #606266;
        font-weight: normal;
      }
    }
    .logo {
      flex: 1;
      display: flex;
      align-items: center;
    }

    //登录后信息
    .login_info {
      display: flex;
      align-items: center;
      position: relative;
      height: 100%;
      font-size: 14px;
      .user_type {
        margin-left: 12px;
      }
      .user_img {
        width: 45px;
        height: 45px;
        border: 1px solid rgba($color: #000, $alpha: 0.08);
        object-fit: contain;
        border-radius: 50%;
      }

      .person_msg {
        width: 110px;
        position: absolute;
        top: 76px;
        right: 0px;
        background: white;
        z-index: 1099;
        display: flex;
        flex-direction: column;
        border-bottom-left-radius: 2px;
        border-bottom-right-radius: 2px;
        box-shadow: 0px 0px 0px 0px #d4d6d7, 0px 0px 0px 0px #d4d6d7,
          2px 0px 5px 1px #d4d6d7, 0px 2px 5px 1px #d4d6d7;

        .item_pop {
          cursor: pointer;
          height: 33px;
          align-items: center;
          justify-content: center;
          display: flex;
          color: black;
          font-size: 12px;
        }

        .item_pop:hover {
          background: rgba($color: #000000, $alpha: 0.1);
          color: $common_bg;
        }
      }
    }

    //消息
    .message {
      cursor: pointer;
      margin-left: 12px;
      margin-right: 18px;
      display: flex;
      align-items: center;

      .msg_view {
        display: flex;
        align-items: center;
        justify-content: center;
        .icon-xiaoxi {
          font-size: 24px;
        }
      }

      .tip {
        margin-left: 2px;
        //提醒文字
        position: relative;

        //未读消息数量
        .num_txt {
          position: absolute;
          top: -16px;
          background: #ff6a6a;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          right: -10px;
          min-width: 20px;
          min-height: 20px;
          color: white;
          font-size: 10px;
        }
      }
    }

    // 用户登录信息以及登录后的头像信息
    .user_message {
      cursor: pointer;
      display: flex;
      align-items: center;
      min-width: 160px;
      // color: white;
      font-size: 14px;
    }

    .course_style {
      cursor: pointer;
    }
  }
}
</style>
