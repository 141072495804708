// 首页
<template>
  <div class="home">
    <!-- 左边的导航条部分 -->
    <HomeLeftMenus></HomeLeftMenus>
    <!-- 右边的视图部分 -->
    <div class="right_view">
      <!--  头部部分 -->
      <CommonHead
        @showUpdatePwd="isShowUpdatePwdDialog = true"
        @showUserInfo="isShowUserInfoDialog = true"
        @clickTopTitleNav="clickTopTitleNav"
        :topTitleArr="topTitleArr"
      ></CommonHead>

      <!-- 间隔线 -->
      <el-divider></el-divider>

      <!-- 路由部分 CampusManagement  -->
      <div class="right_content">
        <keep-alive include="CampusEdit">
          <router-view></router-view>
        </keep-alive>
      </div>

      <!-- 用户资料的弹框 -->
      <UserInfoDialog
        title="用户资料"
        @close="isShowUserInfoDialog = false"
        :showDialog="isShowUserInfoDialog"
        v-if="isShowUserInfoDialog"
      ></UserInfoDialog>

      <!-- 用户资料的弹框 -->
      <UpdatePwdDialog
        title="修改密码"
        @close="isShowUpdatePwdDialog = false"
        :showDialog="isShowUpdatePwdDialog"
        v-if="isShowUpdatePwdDialog"
      ></UpdatePwdDialog>
    </div>
  </div>
</template>
<script>
import HomeLeftMenus from "./HomeLeftMenus.vue";
import CommonHead from "../../components/CommonHead.vue";
//用户资料的弹框
import UserInfoDialog from "@/components/dialog/UserInfoDialog.vue";
//修改密码的弹框
import UpdatePwdDialog from "@/components/dialog/UpdatePwdDialog.vue";
export default {
  name: "Home",
  data() {
    return {
      //默认的顶部标题栏
      topTitleArr: [{ name: "校区管理", path: "/Home/CampusManagement" }],

      //用户资料的弹框
      isShowUserInfoDialog: false,

      //修改密码的弹框
      isShowUpdatePwdDialog: false,
    };
  },
  created() {
    //初始化顶部标题栏的数据,用于刷新页面后的回显操作
    this.initTopTitleArr();
  },
  mounted() {},
  methods: {
    //初次进入时候更新顶部标题栏的方法
    initTopTitleArr() {
      let topArrJson = sessionStorage.getItem(this.$key.TOP_TITLE_ARR);
      if (!this.isEmpty(topArrJson)) {
        this.topTitleArr = JSON.parse(topArrJson);
      }
    },

    //更新顶部标题栏的方法
    updateTopTitleArr(arr) {
      this.topTitleArr.splice(0, this.topTitleArr.length);
      arr.forEach((el) => {
        this.topTitleArr.push({ name: el.name, path: el.path });
      });

      //本地存储一下顶部标题栏的数据数据,用于刷新页面的回显
      sessionStorage.setItem(
        this.$key.TOP_TITLE_ARR,
        JSON.stringify(this.topTitleArr)
      );
    },

    /**
     * 顶部标题的菜单栏点击事件
     */
    clickTopTitleNav(obj) {
      //获取点击的子菜单对象
      let item = obj.item;

      //获取点击的位置
      let index = obj.index;
      if (index == 0) {
        //第一个位置
        if (!this.isEmpty(item.path) && item.path != this.$route.path) {
          this.$router.replace({ path: item.path });
        }
      } else if (this.topTitleArr.length - 1 != index) {
        //非最后一个位置
        this.$router.replace({ path: item.path });
      }
      //将当前点击的标题栏的子菜单的后面所有菜单进行移除
      if (!this.isEmpty(item.path) && this.topTitleArr.length > index) {
        //删除点击子菜单后面的所有元素
        this.topTitleArr.splice(index + 1);
      }
    },
  },
  computed: {},
  beforeDestroy() {
    // sessionStorage.removeItem(this.$key.TOP_TITLE_ARR);
    //清空一下存储的key值
    // console.log("定义的key值是:", this.$key);
    this.$key.clearKey();
  },
  components: {
    HomeLeftMenus,
    CommonHead,
    UserInfoDialog,
    UpdatePwdDialog,
  },
  watch: {
    $route(to, path) {
      //取出当前路由的meta
      // console.log("当前的路由信息是:", this.$route);
      if (
        !this.isEmpty(this.$route.meta) &&
        !this.isEmpty(this.$route.meta.titleArr)
      ) {
        //更新顶部的标题栏的数据
        if (
          this.$route.query.mode == "CampusManagement" ||
          this.$route.query.mode == "ProjectMode"
        ) {
          //校区管理跳转到新增或者编辑
          if (this.$route.query.type == 1) {
            //新建
            this.$route.meta.titleArr[1].name = "新建校区";
          } else if (this.$route.query.type == 2) {
            //编辑
            this.$route.meta.titleArr[1].name = "编辑校区";
          }
        }
        this.updateTopTitleArr(this.$route.meta.titleArr);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  min-width: 1200px;
  display: flex;
  .right_view {
    flex: 1;
    width: 0;
    box-sizing: border-box;
    height: 100vh;
    background: white;
    position: relative;
    display: flex;
    flex-direction: column;

    .right_content {
      flex: 1;
      box-sizing: border-box;
    }

    ::v-deep .el-divider--horizontal {
      margin: 0;
    }
  }
}
</style>
