// 用户信息的弹框
<template>
  <div class="user_info">
    <CommonDialog
      @close="close"
      :showDialog="showDialog"
      :title="title"
      :width="width"
    >
      <template v-slot:content>
        <Layout style="height: 250px" :showLoading="showLoading">
          <template v-slot:content>
            <div class="content margin_left_20 margin_right_20 margin_top_20">
              <!-- 标题 -->
              <!-- <h3>管理系统用户资料</h3> -->

              <!--  所在机构 -->
              <div class="com_dv">
                <span class="min_dv">所在机构：</span
                >{{ userInfo && userInfo.school }}
              </div>

              <!-- 手机号 -->
              <div class="com_dv margin_top_30">
                <span class="min_dv">登录手机号：</span>
                <span>{{ userInfo && userInfo.tel }}</span>
                <span class="margin_left_30"
                  >状态：{{ userInfo && userInfo.state }}</span
                >
                <span class="margin_left_30"
                  >身份：{{ userInfo && userInfo.roleName }}</span
                >
              </div>

              <!-- 用户名 -->
              <div class="com_dv margin_top_30">
                <span class="min_dv">用户名：</span>
                <el-input
                  maxlength="20"
                  clearable
                  v-model="userName"
                  placeholder="输入用户名"
                ></el-input>
              </div>

              <div class="bom">
                <el-button
                  :loading="showCommitLoading"
                  :class="['commit_bt', 'bt_active']"
                  @click.stop="doSaveAction"
                  >保&nbsp;&nbsp;&nbsp;&nbsp;存</el-button
                >
              </div>
            </div>
          </template>
        </Layout>
      </template>
    </CommonDialog>
  </div>
</template>
<script>
//引入通用Dialog对话框
import CommonDialog from "../../components/CommonDialog";
export default {
  data() {
    return {
      //宽度
      width: "520px",

      //输入的用户名
      userName: "",

      //加载框值
      showLoading: -1,

      //提交按钮的loading
      showCommitLoading: false,

      //请求的用户是信息
      userInfo: null,
    };
  },
  created() {
    //请求用户信息接口
    this.http();
  },
  mounted() {},
  methods: {
    //请求用户信息接口
    http() {
      let param = this.getHttpParams();
      param.type = 5;
      this.$http.fetchPost(this.$api.ADMINMANAGE, param).then((res) => {
        let result = res.data;
        if (result.state == 200) {
          //加载布局
          this.showLoading = 1;

          //请求成功
          this.userInfo = result.data;

          //姓名
          this.userName = this.userInfo.name;

          //状态
          this.userInfo.state = this.getState(this.userInfo.state);

          //身份
          this.userInfo.roleName = this.getRoleName(this.userInfo.role);
        } else {
          this.showErrorMsg(this.getMesage(result.state));
        }
      });
    },

    //获取状态
    getState(state) {
      let res = "";
      switch (state) {
        case 0:
          res = "正常";
          break;
        case -1:
          res = "校区到期";
          break;
        case -2:
          res = "禁用";
          break;
      }

      return res;
    },

    //获取用户角色名称
    getRoleName(role) {
      let res = "";
      switch (role) {
        case -1:
          res = "游客";
          break;
        case 0:
          res = "学生";
          break;
        case 1:
          res = "老师";
          break;
        case 2:
          res = "校区负责人";
          break;
        case 3:
          res = "校长";
          break;
        case 4:
          res = "管理员";
          break;
        case 5:
          res = "超级管理员";
          break;
      }
      return res;
    },

    //保存的操作
    doSaveAction() {
      if (this.isEmpty(this.userName)) {
        this.showWarnMsg("请输入用户名");
        return;
      }

      //判断修改的用户名是否包含数字和特殊字符
      //   if (this.regExp(this.userName)) {
      //     this.showWarnMsg("输入的用户名不能包含数字和特殊字符");
      //     return;
      //   }

      //判断用户名是否有修改用户名
      if (this.userName == this.userInfo.name) {
        //表示未修改个人信息
        this.showWarnMsg("未修改个人信息,无需进行保存操作");
        return;
      }

      //加载
      this.showCommitLoading = true;

      let param = this.getHttpParams();
      param.type = 6;
      param.name = this.userName;

      this.$http
        .fetchPost(this.$api.ADMINMANAGE, param)
        .then((res) => {
          let result = res.data;
          this.showCommitLoading = false;
          if (result.state == 200) {
            //请求成功
            this.showSuccessMsg("修改成功");
            //修改个人资料
            this.$bus.$emit("updateUserInfo", this.userName);
            //关闭弹框
            this.close();
          } else {
            this.showErrorMsg(`${result.msg},修改失败`);
          }
        })
        .catch((err) => {
          this.showCommitLoading = false;
          console.log("请求数据异常");
        });
    },

    //关闭弹框
    close() {
      //触发父组件的关闭方法
      this.$emit("close");
    },
  },
  watch: {},
  computed: {},
  components: {
    CommonDialog,
  },
  props: ["showDialog", "title"],
};
</script>
<style lang="scss" scoped>
.user_info {
  ::v-deep .el-dialog {
    top: 50%;
  }

  .content {
    .com_dv {
      display: flex;
      align-items: center;
      .min_dv {
        text-align: right;
        width: 120px;
      }
      ::v-deep .el-input__inner {
        height: 30px;
        line-height: 30px;
        padding-left: 6px;
      }

      ::v-deep .el-input__icon {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      ::v-deep .el-input {
        width: 210px;
      }

      ::v-deep .el-input__suffix {
        right: 0px;
      }
    }

    .bom {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 40px;

      .bt_normal {
        background: #cfd1d4;
        color: white;
        margin-left: 50px;
      }

      .bt_active {
        background: #409eff;
        color: white;
        cursor: pointer;
      }

      .commit_bt {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 32px;
        width: 100px;
        border: none;
      }
    }
  }
}
</style>
