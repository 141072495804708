<template>
  <div class="home_left_menus">
    <!-- 顶部的logo以及名称 -->
    <img class="logo_img" :src="headImage" @click.stop="clickLogImg" />
    <span class="sys_name margin_top_4">{{ appName }}</span>
    <div class="sys_time margin_top_20">欢迎使用！{{ showTime }}</div>

    <!-- 底部的导航栏部分 -->
    <div class="nav_bar margin_top_20">
      <el-menu
        collapse-transition
        :unique-opened="isUniqueOpened"
        :default-active="defaultValue"
        @select="handleSeclect"
        background-color="#274a7d"
        text-color="#fff"
        active-text-color="#ffd04b"
      >
        <!-- 校区管理 权限对应 超级管理员5和普通管理员4-->
        <el-menu-item index="0" v-if="role == 4 || role == 5">
          <i class="margin_right_10 iconfont icon-cheliangguanli"></i>
          <span slot="title">校区管理</span>
        </el-menu-item>

        <!-- 我的校区 权限对应 校长3   -->
        <el-menu-item index="7" v-if="role == 3">
          <i class="margin_right_10 iconfont icon-cheliangguanli"></i>
          <span slot="title">我的校区</span>
        </el-menu-item>

        <!-- 课程管理 权限对应超级管理员5   -->
        <el-menu-item index="1" v-if="role == 5">
          <i class="margin_right_10 iconfont icon-chanpinguanli"></i>
          <span slot="title">课程管理</span>
        </el-menu-item>

        <!-- 教务中心  -->
        <el-submenu index="2">
          <template slot="title">
            <i class="margin_right_10 iconfont icon-jiaowu"></i>
            <span>教务中心</span>
          </template>
          <el-menu-item-group>
            <!-- 我的主页 对应权限 老师1 -->
            <el-menu-item index="2-6" v-if="role == 1"> <span class="margin_left_14" slot="title">我的主页</span></el-menu-item>
            <!-- 教师管理 权限对应 校长3,超级管理员5,普通管理员4 -->
            <el-menu-item index="2-0" v-if="role == 3 || role == 4 || role == 5"> <span class="margin_left_14" slot="title">教师管理</span></el-menu-item>
            <!-- 学员管理 权限对应 校长3,超级管理员5,普通管理员4,老师 1 -->
            <el-menu-item index="2-1" v-if="role == 3 || role == 4 || role == 5 || role == 1">
              <span class="margin_left_14" slot="title">学员管理</span></el-menu-item
            >
            <!-- 班级管理  权限对应 校长3,老师1 -->
            <el-menu-item index="2-2" v-if="role == 3 || role == 1"> <span class="margin_left_14" slot="title">班级管理</span></el-menu-item>
            <!-- 排课管理 权限对应 校长3 -->
            <el-menu-item index="2-3" v-if="role == 3"> <span class="margin_left_14" slot="title">排课管理</span></el-menu-item>
            <!-- 课消记录 权限对应 校长3 -->
            <el-menu-item index="2-4" v-if="role == 3"> <span class="margin_left_14" slot="title">课消记录</span></el-menu-item>
            <!-- 账户明细 权限对应 超级管理员5,普通管理员4,校长3 -->
            <el-menu-item index="2-5" v-if="role == 3 || role == 4 || role == 5"> <span class="margin_left_14" slot="title">账户明细</span></el-menu-item>
          </el-menu-item-group>
        </el-submenu>

        <!-- 教学中心 -->
        <!-- <el-menu-item index="3">
                    <i class="margin_right_10 iconfont icon-jiaoxuezhongxin"></i>
                    <span slot="title">教学中心</span>
                </el-menu-item> -->

        <!-- 营销中心 -->
        <!-- <el-menu-item index="4">
                    <i class="margin_right_10 iconfont icon-yingxiaozhongxin"></i>
                    <span slot="title">营销中心</span>
                </el-menu-item> -->

        <!-- 培训中心 -->
        <!-- <el-menu-item index="5">
                    <i class="margin_right_10 iconfont icon-peixun"></i>
                    <span slot="title">培训中心</span>
                </el-menu-item> -->

        <!-- 数据中心只有校长角色才能查看 -->
        <el-menu-item index="8" v-if="role == 3">
          <i class="margin_right_10 iconfont icon-shuju"></i>
          <span slot="title">数据中心</span>
        </el-menu-item>

        <!-- 系统管理 -->
        <el-submenu index="6">
          <template slot="title">
            <i class="margin_right_10 iconfont icon-xitongguanli"></i>
            <span>系统管理</span>
          </template>

          <el-menu-item-group>
            <!-- 用户管理只有超级管理员才显示 权限对应 超级管理员5 -->
            <el-menu-item index="6-0" v-if="role == 5"> <span class="margin_left_14" slot="title">用户管理</span></el-menu-item>
            <el-menu-item index="6-1"> <span class="margin_left_14" slot="title">消息提醒</span></el-menu-item>
          </el-menu-item-group>
        </el-submenu>
      </el-menu>
    </div>
  </div>
</template>
<script>
//导入配置信息
import AppInfo from "@/config/AppConig.js";
export default {
  data() {
    return {
      //用户头像
      headImage: "",

      //名称
      appName: "",

      //回显的时间
      showTime: "",

      //定时器对象
      timer: null,

      //左侧菜单是否只打开一个
      isUniqueOpened: true,

      //导航菜单
      menuArr: [
        { name: "校区管理", child: [], path: "/Home/CampusManagement" },
        {
          name: "课程管理",
          child: [],
          path: "/Home/ProductManagement",
        },
        {
          name: "教务中心",
          child: [
            {
              name: "教师管理",
              path: "/Home/TeacherManager",
            },
            {
              name: "学员管理",
              path: "/Home/StuManager",
            },
            {
              name: "班级管理",
              path: "/Home/ClassManager",
            },
            {
              name: "排课管理",
              path: "/Home/CourseManager",
            },
            {
              name: "课消记录",
              path: "/Home/ClassAwayRecord",
            },
            {
              name: "账户明细",
              path: "/Home/AccountDetails",
            },
            { name: "我的主页", path: "/Home/MyHomePage" },
          ],
          path: "",
        },
        {
          name: "教学中心",
          child: [],
          path: "/Home/TeachingCenter",
        },
        { name: "营销中心", child: [], path: "/Home/MarketingCenter" },
        { name: "培训中心", child: [], path: "/Home/TrainingCenter" },
        {
          name: "系统管理",
          child: [
            {
              name: "用户管理",
              path: "/Home/UserManager",
            },
            {
              name: "消息提醒",
              path: "/Home/SysMessage",
            },
          ],
          path: "",
        },
        { name: "我的校区", child: [], path: "/Home/MySchool" },
        { name: "数据中心", child: [], path: "/Home/DataCenter" },
      ],

      //默认的菜单激活的位置
      defaultValue: "0",

      //当前登录的用户角色
      role: "",
    };
  },
  created() {
    //初始化App名称和头像
    this.initAppInfo();

    //初始化左侧的菜单栏的选中状态
    this.initDefaultActivedIndex();

    //设置时间
    this.initData();
  },

  mounted() {
    this.timer = setInterval(() => {
      this.initData();
    }, 1000);
  },
  methods: {
    //初始化App名称和头像
    initAppInfo() {
      this.headImage = AppInfo.AppLog;
      this.appName = AppInfo.AppName;
    },
    //同步左侧菜单的选中值的方法
    initDefaultActivedIndex() {
      //获取Vuex中保存的全局左侧菜单栏的选中值
      let defaultValue = sessionStorage.getItem("default_menu_actived_index");
      if (this.isEmpty(defaultValue)) {
        this.defaultValue = "0";
      } else {
        this.defaultValue = defaultValue;
      }
    },
    //设置时间和头像
    initData() {
      //设置时间
      this.showTime = this.formatDate(new Date(), "yyyy-MM-dd HH:mm:ss");

      //获取用户角色 -1游客 0学生  1老师 2校区教务 3校长 4管理员 5超级管理员
      this.role = this.getUserRole();
    },

    /**
     * 菜单激活回调
     * @param index 选中菜单项的 index  是个String
     * @param indexPath 选中菜单项的 index path   是个object
     */
    handleSeclect(index, indexPath) {
      if (this.defaultValue != index) {
        // console.log(
        //     `选中菜单项的 index=${index},选中菜单项的 index path=${indexPath}`
        // );
        if (index != indexPath) {
          //表示点击的是子菜单
          let arr = index.split("-");
          //获取一级菜单的名称和路由,包含子菜单的一级菜单路由为空
          let firstObj = this.menuArr[parseInt(arr[0])];
          // let firstMenuName = firstObj.name;
          // let firstMenuPath = firstObj.path;

          //获取子菜单的名称和路由
          let childObj = firstObj.child[parseInt(arr[1])];
          // let childMenuName = childObj.name;
          let childMenuPath = childObj.path;

          // console.log("获取的一级菜单名称是:;", firstMenuName);
          // console.log("获取的二级菜单名称是:;", childMenuName);
          // console.log("获取的二级菜单路由是:;", childMenuPath);

          //取消保存的页面所有key值
          this.$key.clearKey();
          //进行路由的跳转
          this.$router.push({
            path: childMenuPath,
          });
        } else {
          //表示点击的是一级菜单
          //获取一级菜单的名称
          let firstObj = this.menuArr[parseInt(index)];
          // let firstMenuName = firstObj.name;
          let firstMenuPath = firstObj.path;

          //取消保存的页面所有key值
          this.$key.clearKey();

          //进行路由的跳转
          this.$router.push({
            path: firstMenuPath,
          });
        }
      }
    },

    //头像的点击事件
    clickLogImg() {
      // this.$router.push({ path: "/home/VideoList" });
    },
  },
  destroyed() {
    //销毁时清除全局中保存左侧菜单的选中值
    sessionStorage.setItem("default_menu_actived_index", "");
  },
  computed: {},
  components: {},
  watch: {
    //监听路由变化
    $route(to, from) {
      //获取当前跳转的路由地址
      if (!this.isEmpty(this.$route.meta) && !this.isEmpty(this.$route.meta.defaultIndex)) {
        //记录一下选中菜单的选中状态
        this.defaultValue = this.$route.meta.defaultIndex;

        //全局保存一下选中的索引值,用于刷新页面时同步索引值
        sessionStorage.setItem("default_menu_actived_index", this.defaultValue);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.home_left_menus {
  width: 195px;
  min-width: 195px;
  height: 100vh;
  background: #274a7d;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .logo_img {
    width: 85px;
    margin-top: 10px;
  }

  .sys_name {
    color: white;
    font-size: $tv_base_size;
    font-weight: bold;
  }
  .sys_time {
    color: #35acf7;
    font-size: $tv_sm_size;
  }
  //导航栏的布局
  .nav_bar {
    width: 100%;
    flex: 1;
    overflow-y: scroll;
    margin-top: 20px;
    margin-bottom: 12px;

    ::v-deep .el-menu {
      border-right: none;
    }
  }

  //不显示滚动条
  .nav_bar::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
}
</style>
